html{
   
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6A9C89;
}
body{
  
    width: 100vh;
    height: 100vh;
    display: flex;
    
    justify-content: center;
    align-items: center;

}


form{
   
    background-color: #F5E8B7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px ;
    margin: 20px;
    height: 300px;
    width: 500px;
    cursor: pointer;
    border-radius: 20px;
}

.outerBox{
    display: flex;
    background-color: #C1D8C3;
    position: relative;
    
    
    align-items: center;
    border: 2px ;
    height: 500px;
    width: 800px;
    border-radius: 20px;
}

.innerBox{ 
    display: block;
}

.buttonContainer {
    border: 2px;
    margin: 100px;
    position: absolute;
  
  }
  
.labels{
    font-weight: bolder;
    background-color: #EAD7BB;
    border-radius: 5%;
    margin: 5px;
    padding: 10px;
    
}
.checkboxes{
    display: flex;
    flex-direction: column;
}

.phoneOptions{
    display: flex;
    
   
}